<template>
  <section>
    <div :class="style['mobile-homepage']">
      <div :class="style['mobile-banner-bg']">
        <img :src="image">
      </div>
      <b-row class="mb-3 pb-2 pt-3 mx-0">
        <b-col cols="12">
          <h6 class="text-white w-100 text-center">
            FANPOPZ is a fabulous way to<br>
            meet your idols and icons
          </h6>
        </b-col>
        <b-col
          cols="12"
          class="px-4 py-3"
        >
          <banner-slider />
        </b-col>
      </b-row>
      <b-row
        id="findFab"
        class="mx-0"
      >
        <b-col
          cols="12"
          class="px-4 py-3"
        >
          <h6 class="text-white w-100 text-center">
            Tell us about yourself!
          </h6>
        </b-col>
        <b-col
          cols="12"
          class="my-4 d-flex flex-row justify-content-end align-items-end pb-5 px-4"
        >
          <celebrity-box :suggestedFabList="suggestedFabList" />
        </b-col>
        <b-col
          cols="12"
          class="d-flex flex-column justify-content-start pt-1 align-items-center"
        >
          <b-row>
            <b-col cols="7">
              <b-form-group
                v-slot="{ ariaDescribedby }"
                class="mb-3"
              >
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="selectedCeleb"
                  :options="celebList"
                  :aria-describedby="ariaDescribedby"
                  class="d-flex justify-content-center flex-wrap flex-column"
                  name="radios-stacked"
                  button-variant="px-2 flex-grow-0 text-white text-left"
                  buttons
                  @change="handleCategories"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="5"
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <b-link
                v-if="popularCeleb !== ''"
                :to="'/register'"
                :class="[
                  'btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-3 py-1 text-white',
                  style['follow-btn'],
                ]"
              >
                <span class="follow-btn px-1">Follow</span>
              </b-link>
            </b-col>
          </b-row>
          <b-row class="mx-0 px-1 mb-4">
            <b-col
              cols="12"
            >
              <join-community-form />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <b-col cols="12">
          <Footer />
        </b-col>
      </b-row>
    </div>
  </section>
</template>
<script>
import BannerSlider from "../../components/Home/Mobile/BannerSlider";
import CelebrityBox from "../../components/Celebrity/CelebBox.vue";
import JoinCommunityForm from "../../components/Home/Mobile/JoinCommunityForm.vue";
import style from "../../styles/home.module.scss";
import Footer from '../../components/Footer/Footer.vue';
import image from "../../assets/slide-1.png";
export default {
    components: {
        BannerSlider,
        CelebrityBox,
        JoinCommunityForm,
        Footer,
    },
    props: {
        suggestedFabList: { type: Array, default: () => [] },
        celebList: { type: Array, default: () => [] },
    },
    data() {
        return {
            style,
            popularCeleb: "",
            selectedCeleb: "",
            image
        };
    },
    watch: {
        suggestedFabList: function (newVal) {
            newVal.map((value, index) => {
                if (index === 8) {
                    this.popularCeleb = value.name + " " + value.surname;
                } else if (newVal.length < 9) {
                    if (index === 0) {
                        this.popularCeleb = value.name + " " + value.surname;
                    }
                }
            });
        },
    },
    methods: {
        handleCategories() {
            this.$emit("handleCategoryChange");
        },
    },
};
</script>
