var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.window.width <= 992)?_c('HomeMobile',{attrs:{"suggestedFabList":_vm.suggestedFabList,"celebList":_vm.celebList},on:{"handleCategoryChange":_vm.handleCategories}}):_c('div',{class:[
    _vm.style['content-wrapper'],
    'd-flex flex-column align-items-center' ]},[_c('div',{class:_vm.style['banner-bg']},[_c('img',{attrs:{"src":_vm.image}})]),_c('div',{class:[
      _vm.style['home-container'],
      'd-flex flex-column align-items-center' ]},[_c('div',{staticClass:"text-center pt-5"},[_c('div',[_c('b-row',{staticClass:"mb-3 py-2"},[_c('b-col',{attrs:{"xl-offset":"3","md":"6","xl":"12"}},[_c('h2',{staticClass:"text-white w-100"},[_vm._v(" FANPOPZ is a fabulous way"),_c('br'),_vm._v(" to meet your idols and icons ")])])],1),_c('b-row',{staticClass:"mt-4 mb-5 py-3"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('router-link',{class:[
                _vm.style['register-btn'],
                'align-items-center btn btn-primary px-4 mr-3 text-uppercase' ],attrs:{"to":"/register"}},[_vm._v(" Register ")]),_c('router-link',{staticClass:"align-items-center btn btn-transparent px-4 text-uppercase text-white",attrs:{"to":"/login"}},[_vm._v(" Login ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('banner-slider')],1),_c('b-col',{staticClass:"pt-5",attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-white font-weight-bold py-2"},[_vm._v(" Tell us about yourself! ")])])],1)],1),_c('b-row',{attrs:{"id":"findFab"}},[_c('b-col',{staticClass:"my-4 d-flex flex-column justify-content-end align-items-end pb-5",attrs:{"cols":"12","xl":"7"}},[_c('celebrity-box',{attrs:{"suggestedFabList":_vm.suggestedFabList}})],1),_c('b-col',{class:[
            'd-flex flex-column justify-content-start pt-5',
            _vm.style['category-list'] ],attrs:{"cols":"12","xl":"5"}},[_c('b-form-group',{staticClass:"mb-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{staticClass:"d-flex justify-content-center flex-wrap flex-column text-18",attrs:{"id":"btn-radios-1","options":_vm.celebList,"aria-describedby":ariaDescribedby,"name":"radios-stacked","button-variant":"mr-3 mb-3 px-4 flex-grow-0 text-white text-left","buttons":""},on:{"change":_vm.handleCategories},model:{value:(_vm.selectedCeleb),callback:function ($$v) {_vm.selectedCeleb=$$v},expression:"selectedCeleb"}})]}}])}),_c('div',{staticClass:"text-left pl-3"},[(_vm.popularCeleb !== '')?_c('b-link',{class:[
                'btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-4 py-3 text-white',
                _vm.style['follow-btn'] ],attrs:{"to":'/register'}},[_c('span',{staticClass:"follow-btn px-5"},[_vm._v("Follow")])]):_vm._e()],1)],1)],1),_c('join-community-form',{attrs:{"className":"mb-5 pt-5"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }