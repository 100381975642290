<template>
  <HomeMobile 
    v-if="window.width <= 992" 
    :suggestedFabList="suggestedFabList"
    :celebList="celebList"
    @handleCategoryChange="handleCategories" 
  />
  <div
    v-else
    :class="[
      style['content-wrapper'],
      'd-flex flex-column align-items-center',
    ]"
  >
    <div :class="style['banner-bg']">
      <img :src="image">
    </div>
    <div
      :class="[
        style['home-container'],
        'd-flex flex-column align-items-center',
      ]"
    >
      <div class="text-center pt-5">
        <div>
          <b-row class="mb-3 py-2">
            <b-col
              xl-offset="3"
              md="6"
              xl="12"
            >
              <h2 class="text-white w-100">
                FANPOPZ is a fabulous way<br>
                to meet your idols and icons
              </h2>
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-5 py-3">
            <b-col
              cols="12"
              class="text-center"
            >
              <router-link
                to="/register"
                :class="[
                  style['register-btn'],
                  'align-items-center btn btn-primary px-4 mr-3 text-uppercase',
                ]"
              >
                Register
              </router-link>
              <router-link
                to="/login"
                class="align-items-center btn btn-transparent px-4 text-uppercase text-white"
              >
                Login
              </router-link>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <banner-slider />
            </b-col>
            <b-col
              cols="12"
              class="pt-5"
            >
              <h2 class="text-white font-weight-bold py-2">
                Tell us about yourself!
              </h2>
            </b-col>
          </b-row>
        </div>
        <b-row id="findFab">
          <b-col
            cols="12"
            xl="7"
            class="my-4 d-flex flex-column justify-content-end align-items-end pb-5"
          >
            <celebrity-box :suggestedFabList="suggestedFabList" />
          </b-col>
          <b-col
            cols="12"
            xl="5"
            :class="[
              'd-flex flex-column justify-content-start pt-5',
              style['category-list'],
            ]"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="mb-3"
            >
              <b-form-radio-group
                id="btn-radios-1"
                v-model="selectedCeleb"
                :options="celebList"
                :aria-describedby="ariaDescribedby"
                class="d-flex justify-content-center flex-wrap flex-column text-18"
                name="radios-stacked"
                button-variant="mr-3 mb-3 px-4 flex-grow-0 text-white text-left"
                buttons
                @change="handleCategories"
              />
            </b-form-group>
            <div class="text-left pl-3">
              <b-link
                v-if="popularCeleb !== ''"
                :to="'/register'"
                :class="[
                  'btn btn-pop text-uppercase font-weight-bold mb-3 btn-dark rounded-0 px-4 py-3 text-white',
                  style['follow-btn'],
                ]"
              >
                <span class="follow-btn px-5">Follow</span>
              </b-link>
            </div>
          </b-col>
        </b-row>
        <join-community-form className="mb-5 pt-5" />
      </div>
    </div>
  </div>
</template>
<script>
import BannerSlider from "../../components/Home/NewBannerSlider";
import JoinCommunityForm from "../../components/Home/NewJoinCommunityForm.vue";
import CelebrityBox from "../../components/Celebrity/CelebBox.vue";
import HomeMobile from "./HomeMobile.vue";
import style from "../../styles/home.module.scss";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { APIUrlList } from "../../js/api_url";
import { _sendResponse } from "../../js/common-function";
import { fabList } from "../../api/home";
import { subCategoryList } from "../../api/celebritySearch";
import image from "../../assets/slide-1.png";
export default {
    components: {
        BannerSlider,
        JoinCommunityForm,
        CelebrityBox,
        HomeMobile
    },
    data() {
        return {
            style,
            suggestedFabList: [],
            selectedCeleb: "",
            celebList: [],
            popularCeleb: "",
            image,
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    watch: {
        suggestedFabList: function (newVal) {
            newVal.map((value, index) => {
                if (index === 8) {
                    this.popularCeleb = value.name + " " + value.surname;
                } else if (newVal.length < 9) {
                    if (index === 0) {
                        this.popularCeleb = value.name + " " + value.surname;
                    }
                }
            });
        },
    },
    async created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        if (localStorage.getItem("username") !== null) {
            this.communityFlag = false;
        } else {
            this.communityFlag = true;
        }

        let list = await fabList();
        if (list && list.length > 0) {
            if (list.length > 13) {
                list.length = 13;
            }
            this.suggestedFabList = list;
        }

        let categories = await subCategoryList(null, true);
        if (categories && categories.length > 0) {
            this.celebList = categories;
        }

        if (this.$route.params.id !== undefined) {
            try {
                axios
                    .get(
                        API_BASE_URL + APIUrlList.USERCONFIRM + "/" + this.$route.params.id
                    )
                    .then((response) => {
                        if (response.data.status === false) {
                            _sendResponse("error", response.data.message);
                        } else {
                            _sendResponse("success", response.data.message);
                        }
                    })
                    .catch((error) => {
                        // handle authentication and validation errors here
                        if (error.response.data.errors) {
                            _sendResponse("error", error.response.data.errors);
                        } else {
                            _sendResponse("error", "Unable to Process");
                        }
                    });
            } catch (e) {
                // handle the authentication error here
            }
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        async handleCategories(id) {
            this.suggestedFabList = [];
            let list = await fabList(id);
            if (list && list.length > 0) {
                if (list.length > 13) {
                    list.length = 13;
                }
                this.suggestedFabList = list;
            }
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
};
</script>
