<template>
  <b-row :class="['align-items-center', className]">
    <b-col cols="12">
      <h6 class="text-white text-center font-weight-bold py-2">
        Join the community!
      </h6>
    </b-col>
    <b-col
      cols="12"
      :class="[style['form-container']]"
    >
      <b-row :class="[style['form-wrap']]">
        <b-col
          cols="12"
          class="px-3"
        >
          <form class="text-white">
            <div class="form-group mdi mb-3">
              <input
                id="name"
                v-model="name"
                v-validate="{ required: true }"
                name="name"
                type="text"
                class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                :state="validateState('name')"
                required
              >
              <label
                for="name"
                class="bg-transparent"
              >Name</label>
            </div>
            <span
              id="input-1-live-name"
              class="valdiation_error_home"
            >{{
              veeErrors.first("name")
            }}</span>
            <div class="form-group mdi mb-3">
              <input
                id="surname"
                v-model="surname"
                v-validate="{ required: true }"
                name="surname"
                type="text"
                class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                required
                :state="validateState('surname')"
                data-vv-as="Last Name"
              >
              <label
                for="surname"
                class="bg-transparent"
              >Last Name</label>
            </div>
            <span
              id="input-1-live-name"
              class="valdiation_error_home"
            >{{
              veeErrors.first("surname")
            }}</span>
            <div class="form-group mdi mb-3">
              <input
                id="email"
                v-model="email"
                v-validate="{ required: true }"
                name="email"
                type="text"
                class="form-control border-left-0 border-right-0 border-top-0 bg-transparent join-community"
                required
                :state="validateState('email')"
              >
              <label
                for="email"
                class="bg-transparent"
              >Email address</label>
            </div>
            <span
              id="input-1-live-name"
              class="valdiation_error_home"
            >{{
              veeErrors.first("email")
            }}</span>
            <b-row class="mt-4">
              <b-col
                cols="12"
                class="text-center"
              >
                <b-link
                  :class="[
                    'btn btn-golden text-white px-2 py-2 text-uppercase border-0 w-100 text-center',
                  ]"
                  @click="handleForm"
                >
                  Register
                </b-link>
              </b-col>
              <b-col cols="12">
                <router-link
                  to="/register"
                  class="btn btn-primary py-2 text-uppercase bg-transparent border-0 w-100 text-center"
                >
                  Register via facebook
                </router-link>
              </b-col>
            </b-row>
          </form>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import style from "../../../styles/join-communinty.module.scss";
import joinCommunityImage from "../../../assets/imagesv2/register.png";
export default {
    props: {
        className: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            style,
            name: "",
            surname: "",
            email: "",
            joinCommunityImage,
        };
    },
    methods: {
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleForm() {
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    this.isLoading = false;
                    return;
                }
                let formData = {};
                formData.name = this.name;
                formData.surname = this.surname;
                formData.email = this.email;
                if (formData.name != undefined) {
                    this.$router.push({ path: "/register", query: formData });
                }
            });
        },
    },
};
</script>
