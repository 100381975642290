<template>
  <div
    v-if="celebs.length > 0"
    :class="[style['celebrity-grid-wrap']]"
  >
    <div
      id="celebrity-box"
      :class="[style['celebrity-grid']]"
    >
      <b-row class="mx-0">
        <b-col
          cols="3"
          class="px-0"
        >
          <div
            :class="[style['celeb-detail']]"
            @click="handleSelection(celebs[9])"
          >
            <template v-if="celebs[9]">
              <img
                :src="celebs[9] ? celebs[9].profile_pic_url : placeholderImage"
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[9] ? celebs[9].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[9] ? celebs[9].name : "" }}
                {{ celebs[9] ? celebs[9].surname : "" }}</span>
            </template>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="px-0"
        >
          <div
            :class="[style['celeb-detail']]"
            @click="handleSelection(celebs[5])"
          >
            <template v-if="celebs[5]">
              <img
                :src="celebs[5] ? celebs[5].profile_pic_url : placeholderImage"
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[5] ? celebs[5].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[5] ? celebs[5].name : "" }}
                {{ celebs[5] ? celebs[5].surname : "" }}</span>
            </template>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="px-0"
        >
          <div
            :class="[style['celeb-detail']]"
            @click="handleSelection(celebs[7])"
          >
            <template v-if="celebs[7]">
              <img
                :src="celebs[7] ? celebs[7].profile_pic_url : placeholderImage"
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[7] ? celebs[7].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[7] ? celebs[7].name : "" }}
                {{ celebs[7] ? celebs[7].surname : "" }}</span>
            </template>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="px-0"
        >
          <div
            :class="[style['celeb-detail']]"
            @click="handleSelection(celebs[12])"
          >
            <template v-if="celebs[12]">
              <img
                :src="
                  celebs[12] ? celebs[12].profile_pic_url : placeholderImage
                "
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[12] ? celebs[12].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[12] ? celebs[12].name : "" }}
                {{ celebs[12] ? celebs[12].surname : "" }}</span>
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <b-col
          cols="3"
          class="px-0"
        >
          <b-row class="mx-0">
            <b-col
              cols="12"
              class="px-0"
            >
              <div
                :class="[style['celeb-detail']]"
                @click="handleSelection(celebs[1])"
              >
                <template v-if="celebs[1]">
                  <img
                    :src="
                      celebs[1] ? celebs[1].profile_pic_url : placeholderImage
                    "
                    :class="['h-100 w-100', style['celeb-img']]"
                    @error="setPlaceholderProfileImg"
                  >
                  <span
                    :id="`celebs${celebs[1] ? celebs[1].id : ''}`"
                    :class="[['celeb-name', style['celebrity-name']]]"
                  >{{ celebs[1] ? celebs[1].name : "" }}
                    {{ celebs[1] ? celebs[1].surname : "" }}</span>
                </template>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="px-0"
            >
              <div
                :class="[style['celeb-detail']]"
                @click="handleSelection(celebs[4])"
              >
                <template v-if="celebs[4]">
                  <img
                    :src="
                      celebs[4] ? celebs[4].profile_pic_url : placeholderImage
                    "
                    :class="['h-100 w-100', style['celeb-img']]"
                    @error="setPlaceholderProfileImg"
                  >
                  <span
                    :id="`celebs${celebs[4] ? celebs[4].id : ''}`"
                    :class="[['celeb-name', style['celebrity-name']]]"
                  >{{ celebs[4] ? celebs[4].name : "" }}
                    {{ celebs[4] ? celebs[4].surname : "" }}</span>
                </template>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="6"
          class="px-0"
        >
          <div
            :class="[style['center-celeb-detail']]"
            @click="handleSelection(celebs[0])"
          >
            <template v-if="celebs[0]">
              <img
                :src="celebs[0] ? celebs[0].profile_pic_url : placeholderImage"
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[0] ? celebs[0].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[0] ? celebs[0].name : "" }}
                {{ celebs[0] ? celebs[0].surname : "" }}</span>
            </template>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="px-0"
        >
          <b-row class="mx-0">
            <b-col
              cols="12"
              class="px-0"
            >
              <div
                :class="[style['celeb-detail']]"
                @click="handleSelection(celebs[2])"
              >
                <template v-if="celebs[2]">
                  <img
                    :src="
                      celebs[2] ? celebs[2].profile_pic_url : placeholderImage
                    "
                    :class="['h-100 w-100', style['celeb-img']]"
                    @error="setPlaceholderProfileImg"
                  >
                  <span
                    :id="`celebs${celebs[2] ? celebs[2].id : ''}`"
                    :class="[['celeb-name', style['celebrity-name']]]"
                  >{{ celebs[2] ? celebs[2].name : "" }}
                    {{ celebs[2] ? celebs[2].surname : "" }}</span>
                </template>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="px-0"
            >
              <div
                :class="[style['celeb-detail']]"
                @click="handleSelection(celebs[3])"
              >
                <template v-if="celebs[3]">
                  <img
                    :src="
                      celebs[3] ? celebs[3].profile_pic_url : placeholderImage
                    "
                    :class="['h-100 w-100', style['celeb-img']]"
                    @error="setPlaceholderProfileImg"
                  >
                  <span
                    :id="`celebs${celebs[3] ? celebs[3].id : ''}`"
                    :class="[['celeb-name', style['celebrity-name']]]"
                  >{{ celebs[3] ? celebs[3].name : "" }}
                    {{ celebs[3] ? celebs[3].surname : "" }}</span>
                </template>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <b-col
          cols="3"
          class="px-0"
        >
          <div
            :class="[style['celeb-detail']]"
            @click="handleSelection(celebs[11])"
          >
            <template v-if="celebs[11]">
              <img
                :src="
                  celebs[11] ? celebs[11].profile_pic_url : placeholderImage
                "
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[11] ? celebs[11].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[11] ? celebs[11].name : "" }}
                {{ celebs[11] ? celebs[11].surname : "" }}</span>
            </template>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="px-0"
        >
          <div
            :class="[style['celeb-detail']]"
            @click="handleSelection(celebs[6])"
          >
            <template v-if="celebs[6]">
              <img
                :src="celebs[6] ? celebs[6].profile_pic_url : placeholderImage"
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[6] ? celebs[6].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[6] ? celebs[6].name : "" }}
                {{ celebs[6] ? celebs[6].surname : "" }}</span>
            </template>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="px-0"
        >
          <div
            :class="[style['celeb-detail']]"
            @click="handleSelection(celebs[8])"
          >
            <template v-if="celebs[8]">
              <img
                :src="celebs[8] ? celebs[8].profile_pic_url : placeholderImage"
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[8] ? celebs[8].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[8] ? celebs[11].name : "" }}
                {{ celebs[8] ? celebs[8].surname : "" }}</span>
            </template>
          </div>
        </b-col>
        <b-col
          cols="3"
          class="px-0"
        >
          <div
            :class="[style['celeb-detail']]"
            @click="handleSelection(celebs[10])"
          >
            <template v-if="celebs[10]">
              <img
                :src="
                  celebs[10] ? celebs[10].profile_pic_url : placeholderImage
                "
                :class="['h-100 w-100', style['celeb-img']]"
                @error="setPlaceholderProfileImg"
              >
              <span
                :id="`celebs${celebs[10] ? celebs[10].id : ''}`"
                :class="[['celeb-name', style['celebrity-name']]]"
              >{{ celebs[10] ? celebs[10].name : "" }}
                {{ celebs[10] ? celebs[10].surname : "" }}</span>
            </template>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="celebs.length > 0"
      :class="[style['grid-border']]"
      :style="{ height: grid.height + 'px', width: grid.width + 'px' }"
    />
  </div>
</template>
<script>
import style from "../../styles/celebrity-box.module.scss";
import { setPlaceholderProfileImg } from "../../js/common-function";
import placeholderImage from "../../assets/download.jpeg";
export default {
    props: { suggestedFabList: { type: Array, default: () => [] } },
    data() {
        return {
            style,
            celebs: [],
            popularCeleb: "",
            setPlaceholderProfileImg,
            placeholderImage,
            nrows: 3,
            grid: {
                width: 0,
                height: 0,
            },
        };
    },
    watch: {
        suggestedFabList: function (newVal) {
            this.celebs = [];
            newVal.map((value, index) => {
                if (index === 8) {
                    value.active = true;
                    this.popularCeleb = value.name + " " + value.surname;
                } else if (newVal.length < 9) {
                    if (index === 0) {
                        value.active = true;
                        this.popularCeleb = value.name + " " + value.surname;
                    }
                }
            });
            this.celebs = newVal;
            this.$nextTick(() => {
                this.handleResize();
            });
        },
    },
    async created() {
        window.addEventListener("resize", this.handleResize);
    },
    mounted() {
        this.$nextTick(() => {
            this.handleResize();
        });
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            let grid = document.getElementById("celebrity-box");
            if (typeof grid != "undefined" && grid != null) {
                if (typeof grid.offsetWidth != "undefined") {
                    this.grid.width = grid.offsetWidth;
                }
                if (typeof grid.offsetHeight != "undefined") {
                    this.grid.height = grid.offsetHeight;
                }
            }
        },
        handleSelection(data) {
            //let me = this;
            if (typeof data != "undefined") {
                this.popularCeleb = data.name + " " + data.surname;

                /* this.celebs.findIndex(function (current, index) {
                  if (!current.active) {
                      if (current.id === data.id && me.celebs.length > 8) {
                          var i;
                          for (i = 0; i < me.celebs.length; i++) {
                              let b = me.celebs[index];
                              b.active = true;
                              me.celebs[index] = me.celebs[8];
                              me.celebs[8] = b;
                              me.celebs[index].active = false;
                          }
                          return me.celebs;
                      } else if (current.id === data.id) {
                          for (i = 0; i < me.celebs.length; i++) {
                              let b = me.celebs[index];
                              b.active = true;
                              me.celebs[index] = me.celebs[0];
                              me.celebs[0] = b;
                              me.celebs[index].active = false;
                          }
                          return me.celebs;
                      }
                  }
              }); */
                let celebnames = document.getElementsByClassName("celeb-name");
                [...celebnames].forEach((element) => {
                    element.style.display = "none";
                });

                let celebname = document.getElementById(`celebs${data.id}`);
                if (typeof celebname != "undefined" && celebname != null) {
                    celebname.style.display = "block";
                }
            }
        },
    },
};
</script>
