<template>
  <swiper
    ref="mySwiper"
    :options="swiperOptions"
    :data-id="style['swiper']"
    :class="[style['banner-slider'], 'shadow-lg bg-transparent']"
  >
    <swiper-slide
      v-for="(image, sIndex) in sliderImages"
      :key="`slide${sIndex}`"
    >
      <b-row class="d-flex flex-column mx-0">
        <b-col cols="12">
          <div :class="[style['bg-slider-image-mobile'], 'w-100 h-auto']">
            <img :src="image">
          </div>
          <div class="px-2">
            <b-row class="d-flex flex-row align-items-center justify-content-between py-3">
              <b-col cols="7">
                <h5 class="font-weight-bold mb-md-1 mb-1 text-white">
                  Why FANPOPZ?
                </h5>
              </b-col>
              <b-col cols="5">
                <b-button
                  type="submit"
                  variant="dark"
                  :class="[style['find-popz-btn'], 'btn-pop text-uppercase font-weight-bold px-3 py-2 text-white']"
                  squared
                  @click="scrollToFabs"
                >
                  FIND POPZ
                </b-button>
              </b-col>
            </b-row>
            
            <p :class="['text-white',style['mobile-slide-text']]">
              Join exclusive celebrity social circles.
            </p>
          </div>
        </b-col>
      </b-row>
    </swiper-slide>
    <div
      slot="pagination"
      class="swiper-pagination"
    />
    <div
      slot="button-prev"
      class="swiper-button-prev"
    />
    <div
      slot="button-next"
      class="swiper-button-next"
    />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import imgSlide1 from '../../../assets/banner-1.png';
import imgSlide2 from '../../../assets/orlando-gutierrez.jpeg';
import imgSlide3 from '../../../assets/brunel-johnson.jpeg';
import 'swiper/css/swiper.css';
import style from '../../../styles/banner-slider.module.scss';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        className: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            style,
            swiperOptions: {
                direction: 'vertical',
                loop:true,
                autoplay: false,
                speed:1000,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            sliderImages: [imgSlide1, imgSlide2, imgSlide3]
        };
    },
    methods: {
        scrollToFabs() {
            const findFabEle = document.getElementById('findFab');
            if (findFabEle) {
                window.scroll(0, findFabEle.offsetTop);
            }
        }
    }
};
</script>